<template>
  <v-container fluid>
    <v-row justify="center" class="mt-5" style="height: 50vh;">
      <v-col cols="8" align-self="center">
        <h1>404</h1>
        <h3>Seite nicht gefunden</h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Seite nicht gefunden',
}
</script>
